import { createSlice } from '@reduxjs/toolkit';
import { messageDetailsReducer } from './getMessageDetailsReducer';
import { MessageDetailState } from './type';

export const initialState: MessageDetailState = {
  isLoading: false,
  messageDetail: {
    id: 0,
    createdAt: '',
    updatedAt: '',
    messages: [],
  },
  error: ''
};

const slice = createSlice({
  name: 'messageDetails',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    messageDetailsReducer(builder)
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
