import React, { useState, useEffect } from 'react';
import { Dialog, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useSelector } from 'store';
import Button from 'components/Button';
import { SignupParams } from 'apis/auth';
import { actions } from 'slices/auth';
import { signupThunk } from 'slices/auth/signupReducer';
import { getCommonThunk } from 'slices/general/getCommonReducer';
import { SIGNUP_STEPS, USER_ROLES } from '../../../constants';
import { routesConstant } from '../../../constants/routeConstant';
import RoleForm from './RoleForm';
import UserForm from './UserForm';
import PasswordForm from './PasswordForm';
import TermsForm from './TermsForm';

const SignupModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { openSignupModal } = useSelector((state) => state.auth);
  const [signupStep, setSignupStep] = useState(SIGNUP_STEPS.UserForm);
  const [formData, setFormData] = useState<SignupParams>();

  const { commonDefination } = useSelector((state) => state.general);

  useEffect(() => {
    if (!commonDefination) dispatch(getCommonThunk());
  }, [commonDefination]);

  const resetStep = () => {
    setSignupStep(SIGNUP_STEPS.UserForm);
  };

  const toggleSignupModal = (open: boolean) => () => {
    dispatch(actions.toggleSignupModal(open));
    resetStep();
  };

  const toggleLoginModal = (open: boolean) => () => {
    dispatch(actions.toggleLoginModal(open));
    resetStep();
  };

  const handleUserFormChange = (formData: SignupParams, nextStep: string) => {
    setFormData((pre) => ({ ...pre, ...formData }));
    setSignupStep(nextStep);
  };

  const handleRoleFormChange = (formData: SignupParams, nextStep: string) => {
    setFormData((pre) => ({ ...pre, ...formData }));
    setSignupStep(nextStep);
  };

  const handlePasswordFormChange = (formData: SignupParams, nextStep: string) => {
    setFormData((pre) => ({ ...pre, ...formData }));
    setSignupStep(nextStep);
  };

  const handleSubmit = () => {
    const params = formData as SignupParams;
    // application lang
    params.language = i18n.resolvedLanguage || 'en';

    if (params.role === USER_ROLES['Vendor(Serviceprovider)']) {
      const defaultLangIsoCode = i18n.language || 'en';
      const defaultLang = commonDefination?.languages.find(
        (lang) => lang?.code === defaultLangIsoCode
      );
      if (defaultLang) {
        params.languages = [
          {
            id: defaultLang.id,
            name: defaultLang.name,
            experienceLevel:
              commonDefination?.languageLevelList[0]?.value || 'basic',
          },
        ];
      }
    }

    dispatch(
      signupThunk({
        ...params,
        username: params.email,
        fullName: `${params.firstName} ${params.lastName}`
      })
    ).then((action) => {
      if (action?.meta?.requestStatus === 'fulfilled') {
        resetStep();
        if (params.role === USER_ROLES['Institution(ProjectLead)']) {
          navigate(routesConstant.projectLead.onboardingIsLeadOnly);
        }
        else if (params.role === USER_ROLES.multipleRoles) {
          navigate(routesConstant.projectLead.onboarding);
        }
        else if ((params.role === USER_ROLES['Vendor(Serviceprovider)'] || params.role === USER_ROLES.multipleRoles)) {
          navigate(routesConstant.vendor.onboarding.general);
        }
        else {
          navigate(routesConstant.dashboard);
        }
        toast.success(t('signupSuccessfully'));
      }
    });
  };

  const handleBack = () => {
    if (signupStep === SIGNUP_STEPS.RoleForm) {
      setSignupStep(SIGNUP_STEPS.UserForm)
    }
    else if (signupStep === SIGNUP_STEPS.PasswordForm) {
      setSignupStep(SIGNUP_STEPS.RoleForm)
    }
    else if (signupStep === SIGNUP_STEPS.TermsForm) {
      setSignupStep(SIGNUP_STEPS.PasswordForm)
    }
  };

  return (
    <Dialog
      open={openSignupModal}
      onClose={toggleSignupModal(false)}
      maxWidth="md"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: 540,
          '@media screen and (max-width: 599px)': {
            width: '100%',
          },
          minHeight: 560,
          pt: (theme) => theme.spacing(4),
          px: (theme) => theme.spacing(5),
          pb: (theme) => theme.spacing(4.5),
        }}
      >
        <Box sx={{ flex: 1, mb: 2 }}>
          {signupStep !== SIGNUP_STEPS.UserForm && (
            <Button
              variant="outlined"
              color="primary"
              sx={{
                padding: '12px 20px',
                textTransform: 'none',
                '@media screen and (max-width: 599px)': {
                  marginBottom: '24px',
                  padding: '15px 20px',
                  width: '100%',
                },
              }}
              onClick={() => handleBack()}
            >
              {t('goBack')}
            </Button>
          )}
          <Typography
            variant="h5"
            fontWeight="fontWeightBold"
            color="primaryText"
            sx={{ mb: 2, textAlign: 'center' }}
          >
            {t('formTitleSignup')}
          </Typography>
          {signupStep === SIGNUP_STEPS.UserForm && (
            <UserForm
              onChange={handleUserFormChange}
            />
          )}
          {signupStep === SIGNUP_STEPS.RoleForm && (
            <RoleForm onChange={handleRoleFormChange} />
          )}
          {signupStep === SIGNUP_STEPS.PasswordForm && (
            <PasswordForm onChange={handlePasswordFormChange} />
          )}

          {signupStep === SIGNUP_STEPS.TermsForm && (
            <TermsForm handleSubmit={handleSubmit} />
          )}

        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1" fontWeight="600">
            {t('alreadyRegistered')}
          </Typography>
          <Button
            variant="text"
            color="secondary"
            sx={{
              padding: 0,
              textTransform: 'none',
            }}
            onClick={toggleLoginModal(true)}
          >
            {t('login')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SignupModal;
