/** https://mui.com/customization/default-theme/ */

import { createTheme, ThemeOptions } from '@mui/material/styles';
import { defaultShadows } from './shadows';
import * as Colors from './color';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: Colors.border,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          height: '36px',
          fontSize: '15px',
          lineHeight: '16px',
          borderRadius: '100px',
          borderColor: Colors.outlineButtonBorder,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true, // No more ripple!
      },
      styleOverrides: {
        outlined: {
          borderColor: Colors.outlineButtonBorder,
        },
        sizeSmall: {
          fontSize: 15,
          lineHeight: '16px',
          padding: '10px 30px',
        },
        sizeMedium: {
          fontSize: 15,
          lineHeight: '16px',
          padding: '16px 30px',
        },
        sizeLarge: {
          fontSize: 18,
          lineHeight: '16px',
          padding: '24px 30px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(24, 26, 27, .98)',
          borderRadius: '4px',
          padding: '6px 10px',
        },
        arrow: {
          color: 'rgba(24, 26, 27, .98)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
        '.truncate': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: '-webkit-box',
          boxOrient: 'vertical',
          '-webkit-box-orient': 'vertical',
          lineClamp: '1',
          '-webkit-line-clamp': 1,
          '&--2': {
            lineClamp: '2',
            '-webkit-line-clamp': '2',
          },
          '&--3': {
            lineClamp: '3',
            '-webkit-line-clamp': '3',
          },
          '&--4': {
            lineClamp: '4',
            '-webkit-line-clamp': '4',
          },
          '&--5': {
            lineClamp: '5',
            '-webkit-line-clamp': '5',
          },
        },
        '.swiper-button-next': {
          color: 'white !important',
        },
        '.swiper-button-prev': {
          color: 'white !important',
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              paddingLeft: '36px',
              paddingRight: '36px',
            },
          },
        },
        '.MuiMenuItem-root.show-checkmark': {
          paddingLeft: '36px',
          paddingRight: '36px',
        },
        '.MuiMenuItem-root.Mui-selected': {
          backgroundColor: `${Colors.secondary} !important`,
          color: 'white !important',
        },
        '.MuiMenuItem-root.show-checkmark.Mui-selected::before': {
          content: '" "',
          display: 'inline-block',
          transform: 'rotate(45deg)',
          height: '17px',
          width: '7px',
          borderBottom: '2px solid white',
          borderRight: '2px solid white',
          position: 'absolute',
          left: '16px',
          top: '9px',
        },
        '.MuiFormLabel-root.MuiFormLabel-colorPrimary': {
          color: `${Colors.primary} !important`,
          fontWeight: 'bold',
          marginBottom: 8,
        },
        '.MuiFormHelperText-root': {
          color: `${Colors.error} !important`,
          fontSize: '14px !important',
          fontWeight: 700,
          margin: '4px 14px 0',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    fontFamily: 'FrutigerLT',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    button: {
      fontWeight: 700,
    },
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '40px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '30px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '1rem',
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    subtitle2NoBold: {
      fontWeight: 400,
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    // MUI default action configuration
    action: {
      active: 'rgba(0,0,0,0.54)',
      hover: 'rgba(0,0,0,0.04)',
      // hoverOpacity: 0.04,
      hoverOpacity: 0,
      selected: 'rgba(0,0,0,0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0,0,0,0.26)',
      disabledOpacity: 0.38,
      disabledBackground: 'rgba(0,0,0,0.12)',
      focus: 'rgba(0,0,0,0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    background: {
      default: Colors.backgroundDefault,
      paper: '#ffffff',
    },
    error: {
      contrastText: '#ffffff',
      main: Colors.error,
    },
    mode: 'light',
    primary: {
      contrastText: '#ffffff',
      main: Colors.primary,
    },
    secondary: {
      contrastText: '#ffffff',
      main: Colors.secondary,
      light: Colors.secondaryLight,
    },
    tertiary: {
      contrastText: Colors.primaryText,
      main: Colors.tertiary,
    },
    success: {
      contrastText: '#ffffff',
      main: Colors.success,
      dark: Colors.darkSuccess,
    },
    text: {
      primary: Colors.primaryText,
      secondary: Colors.secondaryText,
      disabled: Colors.disabledText,
    },
    warning: {
      contrastText: '#ffffff',
      main: Colors.warning,
    },
    divider: Colors.border,
  },
  shadows: defaultShadows,
} as ThemeOptions;

export const createCustomTheme = (config = { responsiveFontSizes: false }) => {
  const themeOptions = baseOptions;

  const theme = createTheme(themeOptions);

  return theme;
};
