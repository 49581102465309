import Axios from 'axios';
import NProgress from 'nprogress';
import i18n from 'i18next';
import toast from 'react-hot-toast';
import { ECA_ACCESS_TOKEN } from '../constants';
import store from '../store';
import { actions } from 'slices/auth';
import { ValidationError, ValidationErrorResponse } from 'slices/project/type';

const URLS_SUPPORT_LOCALE = [
  '/common-define',
  '/locations',
  '/services',
  '/industries',
  '/softwares',
  '/skills',
  '/languages',
  '/end-users',
  '/banners',
  '/project'
];

const isSupportedLocal = (url?: string) => {
  return URLS_SUPPORT_LOCALE.some((item) => url?.startsWith(item));
};

export interface Pagination {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}
export interface PaginationParam {
  page: number;
  pageSize: number;
}
export interface Meta {
  pagination: Pagination;
}

export interface ListResponse<T> {
  data: T[];
  meta: Meta;
}
export interface MutationResponse<T> {
  data: T;
  meta: Meta;
}
export interface DetailResponse<T> {
  data: T;
}

export interface StrapiFile {
  id: number;
  url: string;
  name: string;
  formats: {
    small: {
      url: string;
    };
    medium: {
      url: string;
    };
  };
}

const api = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

api.interceptors.request.use((config) => {
  NProgress.start();
  // if (config.method === 'get' && isSupportedLocal(config.url)) {
  if (isSupportedLocal(config.url)) {
    if (!config.params) {
      config.params = { locale: i18n.resolvedLanguage };
    } else {
      config.params.locale = i18n.resolvedLanguage;
    }
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response.data;
  },
  async (error) => {
    NProgress.done();
    const errorStatus = error.response?.data?.error?.status;
    if (errorStatus === 401) {
      toast.error(i18n.t('tokenExpired'));
      store.dispatch(actions.logout());
    }
    const errorMsg =
      error.response?.data?.error?.message ||
      error.response?.error?.message ||
      error.message;
    const errorName = error.response?.data.error.name;    
    if (errorName === "ValidationError") {
      let validationErrors: ValidationError[] = error.response?.data.error.details.errors;
      let errorMessage: ValidationErrorResponse = {
        status: errorStatus,
        name: errorName,
        message: errorMsg,
        details: {
          errors: validationErrors
        }
      }
      return Promise.reject(errorMessage)
    }
    else {
      return Promise.reject(new Error(errorMsg));
    }
  }
);

export const setRequestAuthorizationHeader = (token?: string) => {
  api.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
};

export const setAccessToken = (accessToken?: string) => {
  if (accessToken) {
    window.localStorage.setItem(ECA_ACCESS_TOKEN, accessToken);
    setRequestAuthorizationHeader(accessToken);
  } else {
    window.localStorage.removeItem(ECA_ACCESS_TOKEN);
    setRequestAuthorizationHeader('');
  }
};

export default api;
