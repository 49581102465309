import api, { ListResponse } from './base';
import { User } from './auth';
import { ProjectParams } from './project';

export interface GetMatchedVendorsParams {
  'filters[services]'?: string;
  'filters[industries]'?: string;
  'filters[languages]'?: string;
  'filters[provinces]'?: string;
  'pagination[page]'?: number;
  'pagination[pageSize]'?: number;
  'sort[0]'?: string;
  'sort[1]'?: string;
}

export interface VendorProfile extends User {
  isConnectable?: boolean;
  isVendorOfRecord?: boolean;
  matched?: {
    status: string;
  };
}

export interface MatchedVendorInfo {
  id: number;
  attributes: {
    contactName: string;
    companyName: string;
    companySize: string;
    organizeType: string;
    primaryProvinceName: string;
    primaryCityName: string;
    fullName: string;
    group: number;
    isVendorOfRecord?: boolean;
    matched: {
      id: number;
      createdAt: string;
      rejectedDescription: string | null;
      status: string; // might want to extract the status type from the matched project
      updatedAt: string;
      project : ProjectParams;
    };
  };
}
export interface SelectVendorParams {
  data: {
    projectId: number;
    userId: number;
    agreementFile?: number;
  };
}

export interface SendConnectionParams {
  user: number;
  project: number;
  status: string;
}

export const getMatchedVendorsApi = async (
  id: number,
  params: GetMatchedVendorsParams
) =>
  api.get(`/matcheds/get-matched-vendors/${id}`, { params }) as Promise<
    ListResponse<MatchedVendorInfo>
  >;

export const getMatchedVendorApi = async (
  vendorId: number,
  projectId: number
) =>
  api.get(
    `/matcheds/vendor/${vendorId}/${projectId}`
  ) as Promise<VendorProfile>;

export const selectMainVendorApi = async (params: SelectVendorParams) =>
  api.post('/matcheds/vendor/select-main', params) as Promise<{ ok: boolean }>;

export const connectToVendorApi = async (params: SendConnectionParams) =>
  api.post('/matcheds', { data: params }) as Promise<VendorProfile>;
