import { useMemo, useState } from 'react';
import { Alert, Box, Grid, MenuItem, FormLabel, TextField, Typography, ListSubheader, FormControlLabel, RadioGroup, Radio, FormHelperText } from '@mui/material';
import Button from 'components/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useSelector } from 'store';
import { grey5 } from 'theme/color';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesConstant } from '../../constants/routeConstant';
import toast from 'react-hot-toast';
import { updateProfileThunk } from 'slices/auth/updateProfileReducer';
import { VENDOR_ONBOARDING_STEPS } from '../../constants';
import TextMaskCustom from 'components/TextMaskCustom';
import { getCommonThunk } from 'slices/general/getCommonReducer';

const SubscriptionPlanTypes = {
  Monthly: 'monthly',
  Annual: 'annual',
};

const ProjectLeadOnboardingSubscriptionPlan = () => {
  const { t } = useTranslation(['projectlead', 'common']);
  const { user } = useSelector((state) => state.auth);
  const { error, isProcessing } = useSelector((state) => state.auth);
  //const { provinces } = useSelector((state) => state.province);
  const { commonDefination } = useSelector((state) => state.general);
  //const [isNonPartner, setIsNonPartner] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get the current location object
  const location = useLocation();

  // Extract query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get a specific query parameter by name
  const isFromSettings = queryParams.get('fromsettings') == '1' ? true : false;
  const isLeadOnly = queryParams.get('isleadonly') == '1' ? true : false;
  const updateSettings = (value: any) => {
    //var contactNo = value.organizationNumber.replaceAll("-", "");
    var settings: any = {
      step: VENDOR_ONBOARDING_STEPS.ExperienceAndPlan,
      fullName: user?.fullName,
      email: user?.email,
      // organizeType: user?.organizeType,
      // companySize: user?.companySize,
      // description: user?.description,
      preCVLPExp: user?.preCVLPExp,
      // locations: user?.locations,
      // associatedInstitution: isNonPartner ? values.organizationName : values.instituteName,
      subscriptionPlan: values.subscriptionPlan,
    };

    var settingsWithRole: any = {
      step: VENDOR_ONBOARDING_STEPS.ExperienceAndPlan,
      fullName: user?.fullName,
      email: user?.email,
      // organizeType: user?.organizeType,
      // companySize: user?.companySize,
      // description: user?.description,
      preCVLPExp: user?.preCVLPExp,
      // locations: user?.locations,
      // associatedInstitution: isNonPartner ? values.organizationName : values.instituteName,
      subscriptionPlan: values.subscriptionPlan,
      role: {
        id: 4,
        type: 'multiple_user'
      }
    }

    dispatch(
      updateProfileThunk(isFromSettings ? settingsWithRole : settings)
    ).then((action: any) => {
      if (action?.meta?.requestStatus === 'fulfilled') {
        dispatch(getCommonThunk());
        toast.success(t('common:success'));
         
          navigate(routesConstant.dashboard);
         
      }
      else {
        toast.error(t('common:errorInSave'));
      }
    });
  };

  const leadInstitionNameOptions = useMemo(() => {
    const institutionNames = commonDefination?.institutionNames || [];
    const items: any[] = [];
    institutionNames.forEach((group: any) => {
      items.push(
        <ListSubheader
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
          }}
          key={group.value}
        >
          {group.label}
        </ListSubheader>
      );
      group.options.forEach((option: any) => {
        items.push(
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        );
      });
    });
    return items;
  }, [commonDefination]);

  const {
    values,
    touched,
    errors,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      subscriptionPlan: user?.subscriptionPlan || '',
    },
    validationSchema: Yup.object().shape({
      subscriptionPlan: Yup.string().required(t('common:missing')),
    }),
    onSubmit: async (values) => {
      updateSettings(values);
    },
  });

  const handleResetForm = () => {
    setValues({
      subscriptionPlan: '',      
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={4} display="flex" flexDirection="column">
          <Box>
            <Typography variant="h2" component="span" sx={{ mr: 4 }}>
              {t('projectlead:pageHeader.ProjectLeadProfile')}
            </Typography>
            <Typography variant="subtitle2" component="span">
              {t('projectlead:pageLabel.step')} 2 / 2
            </Typography>
            <Typography
              variant="body2"
              component="span"
              color={grey5}
              sx={{ paddingLeft: 1 }}
            >
              {t('projectlead:pageLabel.allRequired')}
            </Typography>
          </Box>
          <Typography variant="body1" color={grey5} sx={{ mt: 4.5 }}>
            {t('projectlead:pageLabel.enterInformation')}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Button
            loading={isProcessing}
            variant="text"
            onClick={handleResetForm}>
            {t('projectlead:button.reset')}
          </Button>
          <Button
            loading={isProcessing}
            variant="contained"
            color="secondary"
            type="submit"
            sx={{ ml: 2 }}
          >
            { t('projectlead:button.done')}
          </Button>
        </Box>
      </Box>
      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Grid container spacing={4} sx={{ mt: 3, mb: 5, marginLeft: '0', width: '100%' }}>
      <Grid item md={12} xs={12}>
      <Typography variant="h4" sx={{ mt: 6.5, mb: 3 }}>
            {t('projectlead:general.selectSubscriptionPlan')}
          </Typography>
 
          <RadioGroup
            name="subscriptionPlan"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.subscriptionPlan}
          >
            <FormControlLabel
              control={<Radio color="primary" />}
              label={<div  dangerouslySetInnerHTML={{ __html: t('projectlead:general.selectMonthlySubscriptionPlan')  }} />}
              value={SubscriptionPlanTypes.Monthly}
            />
            <FormControlLabel
              control={<Radio color="primary" />}
              label={<div  dangerouslySetInnerHTML={{ __html: t('projectlead:general.selectAnnualSubscriptionPlan')  }} /> }
              value={SubscriptionPlanTypes.Annual}
            />
          </RadioGroup>

          {touched.subscriptionPlan && errors.subscriptionPlan && (
            <FormHelperText error>{errors.subscriptionPlan}</FormHelperText>
          )}

          <Typography variant="h4" sx={{ mt: 6.5, mb: 3 }}>
            {<div  dangerouslySetInnerHTML={{ __html: t('projectlead:general.selectSubscriptionHelpText') }} />}
          </Typography>
          </Grid>
      </Grid>

    </form>
  );
};

export default ProjectLeadOnboardingSubscriptionPlan;

