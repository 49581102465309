import { Box, Container, Link } from '@mui/material';
import { routesConstant } from 'constants/routeConstant';
import { useTranslation } from 'react-i18next';
import { grey4, grey5 } from 'theme/color';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ backgroundColor: 'primary.main' }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            paddingY: { xs: 4, md: 6 },
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: '28px', md: '0' },
            '@media screen and (max-width: 767px)': { paddingTop: '34px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'flex-start',
              gap: { xs: '28px', md: '56px' },
            }}
          >
            <img alt="ecampus" src="/static/logo-light.svg" />
            <Box
              sx={{
                width: { xs: '100%', md: '62ch' },
                fontSize: '14px',
                lineHeight: '22px',
                color: 'divider',
                position: 'relative',
                top: { xs: '0', md: '-2px' },
              }}
            >
              {t('footer.motto')}
            </Box>
          </Box>
          <Box sx={{ width: '150px', height: '40px' }}>
            <img alt="ecampus" src="/static/logo-ontario.svg" />
          </Box>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: grey5, height: '1px' }}></Box>
      <Container maxWidth="xl">
        <Box
          sx={{
            paddingY: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '24px', sm: '0' },
          }}
        >
          <Box sx={{ fontSize: '13px', lineHeight: '24px', color: grey4 }}>
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </Box>
          <Link
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              color: 'divider',
            }}
            href={routesConstant.privacyPolicy}
          >
            {t('footer.privacyPolicy')}
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
