
import React, { useState } from 'react';
import { Button, FormGroup, FormControlLabel, Checkbox, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { error } from 'theme/color';
import { SignupParams } from 'apis/auth';
import { USER_ROLES, SIGNUP_STEPS } from '../../../constants';
import { Formik } from 'formik';
import * as Yup from 'yup';

interface Props {
  onChange: (formData: SignupParams, nextStep: string) => void;
}

const RoleForm: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('common');
  const [isContinue, setIsContinue] = useState(false);
  const [roleErrorDisplay, setRoleErrorDisplay] = useState(false);

  return (
    <Formik
      initialValues={{
        projectLead: false,
        serviceProvider: false,
      }}
      validationSchema={Yup.object().shape({

      })}
      onSubmit={async (values) => {
        // Your form submission logic here
        setRoleErrorDisplay(false);
        console.log('data', values);
        if (!values.projectLead && !values.serviceProvider) {
          setRoleErrorDisplay(true);
          return;
        }
        let selectedRole;
        if (values.serviceProvider && values.projectLead) {
          selectedRole = USER_ROLES['multipleRoles'];
        }
        else if (values.projectLead) {
          selectedRole = USER_ROLES['Institution(ProjectLead)'];
        }
        else {
          selectedRole = USER_ROLES['Vendor(Serviceprovider)'];
        }

        onChange({
          role: selectedRole
        } as SignupParams
          , SIGNUP_STEPS.PasswordForm);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              marginBottom: '14px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                marginBottom: '8px',
                marginTop: '24px',
              }}
            >
              {t('selectRoles')}
            </Typography>
            <FormGroup>
              <FormControlLabel
                sx={{
                  color: errors.serviceProvider && touched.serviceProvider ? '#c00c00' : 'inherit'
                }}
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    checked={values.serviceProvider}
                    onChange={(event) => { handleChange(event); setRoleErrorDisplay(false); }}
                    name="serviceProvider"
                  />
                }
                label={t('serviceProvider')}
                value={values.serviceProvider}
              />
              <FormControlLabel
                sx={{
                  color: errors.projectLead && touched.projectLead ? '#c00c00' : 'inherit'
                }}
                control={
                  <Checkbox
                    checked={values.projectLead}
                    onBlur={handleBlur}
                    name="projectLead"
                    onChange={(event) => { handleChange(event); setRoleErrorDisplay(false); }}
                  />
                }
                label={t('projectLead')}
                value={values.projectLead}
              />
            </FormGroup>

            {roleErrorDisplay &&
              <Typography
                color={error}
                fontWeight="600"
                sx={{ mt: 1 }}
              >
                {t('selectRoleError')}
              </Typography>
            }
          </Box>
          <Button
            sx={{ mt: 5, textTransform: 'none' }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            onClick={() => setIsContinue(true)}
          >
            {t('button.continue')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default RoleForm;