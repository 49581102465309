import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectDetailApi, updateProjectDraftStatusApi } from 'apis/project';
import { ProjectState } from './type';

export const getProjectDetailThunk = createAsyncThunk(
  'project/getProjectDetail',
  async (id: number) => {
    const ProjectDetail = await getProjectDetailApi(id);
    return ProjectDetail;
  }
);

export const updateProjectDraftStatusThunk = createAsyncThunk(
  'project/updateProjectDetail',
  async (id: number) => {
    const ProjectDetail = await updateProjectDraftStatusApi(id);
    return ProjectDetail;
  }
);

export const getProjectDetailReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(getProjectDetailThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
    state.projectDetail = undefined;
    state.projectConnection = undefined;
  });
  builder.addCase(getProjectDetailThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.projectDetail = payload.data;
  });
  builder.addCase(getProjectDetailThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
