
import React, { useState } from 'react';
import { Button, FormControlLabel, Checkbox, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { error, grey5 } from 'theme/color';
import { Formik } from 'formik';
import * as Yup from 'yup';

interface Props {
    handleSubmit: () => void;
}

const TermsForm: React.FC<Props> = ({ handleSubmit }) => {
    const { t } = useTranslation('common');
    const [isAcceptedError, setIsAcceptedError] = useState(false);

    return (
        <Formik
            initialValues={{
                isAccepted: false
            }}
            validationSchema={Yup.object().shape({
                isAccepted: Yup.boolean().required()
            })}
            onSubmit={async (values) => {
                // Your form submission logic here
                setIsAcceptedError(false);
                if (!values.isAccepted) {
                    setIsAcceptedError(true);
                    return;
                }
                handleSubmit();
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
            }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Typography
                        sx={{
                            mt: 3,
                            mb: 1,
                            marginX: 5,
                            color: grey5,
                            textAlign: 'center',
                            height: '150px',
                            fontSize: '18px'
                        }}
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: t("agreeToTerms") }}
                    >
                     
                    </Typography>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.isAccepted}
                                    onChange={(event) => { handleChange(event); setIsAcceptedError(!event.target.checked) }}
                                    name="isAccepted"
                                    onBlur={handleBlur}
                                />
                            }
                            label={t("agreeText")}
                            value={values.isAccepted}
                        />
                        {isAcceptedError && (
                            <Typography
                                color={error}
                                fontWeight="600"
                                sx={{ mt: 1 }}
                            >
                                {t("acceptText")}
                            </Typography>
                        )}
                    </Box>
                    <Button
                        sx={{ mt: 5, textTransform: 'none' }}
                        fullWidth
                        color="secondary"
                        variant="contained"
                        type="submit"
                    >
                        {t("join")}
                    </Button>
                </form>
            )}
        </Formik>
    );
};

export default TermsForm;