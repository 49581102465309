import { createSlice } from '@reduxjs/toolkit';
import { GlobalSettingState } from './type';
import { getGlobalSettingReducer } from './getGlobalSettingsReducer';

export const initialState: GlobalSettingState = {
  isLoading: false,
  isProcessing: false,
  globalSetting: undefined,  
  error: undefined,
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getGlobalSettingReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
