import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { MessageDetailState } from './type';
import { getMessageDetails } from 'apis/message';

export const getMessageDetailsThunk = createAsyncThunk(
    '/message/messageDetail',
    async (id: number) => {
        return await getMessageDetails(id)
    }
);

export const messageDetailsReducer = (
    builder: ActionReducerMapBuilder<MessageDetailState>
): void => {
    builder.addCase(getMessageDetailsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
    });
    builder.addCase(getMessageDetailsThunk.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.messageDetail = payload;
    });
    builder.addCase(getMessageDetailsThunk.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
    });
};