export const ECA_ACCESS_TOKEN = 'ecaAccessToken';
export const ECA_USER = 'ecaUser';
export const PASSWORD_REGREX =
  /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,}))$/;

export const USER_ROLES: Record<
  'Vendor(Serviceprovider)' | 'Institution(ProjectLead)' | 'CvlpAdmin(ecoAdmin)' | 'multipleRoles',
  'authenticated' | 'institution' | 'cvlpadmin' | 'multiple_roles'
> = {
  "Vendor(Serviceprovider)": 'authenticated', // Vendor is default strapi role
  "Institution(ProjectLead)": 'institution',
  "CvlpAdmin(ecoAdmin)": 'cvlpadmin',
  "multipleRoles": 'multiple_roles'
};

export const SIGNUP_STEPS = {
  RoleForm: '1',
  UserForm: '2',
  PasswordForm: '3',
  TermsForm: '4'
};

export const VENDOR_ONBOARDING_STEPS = {
  General: 'general',
  ExperienceAndPlan: 'experienceAndPlan',
  Service: 'service',
  Industry: 'industry',
  Software: 'software',
  LinksAndPortfolio: 'linksAndPortfolio',
  Language: 'language',
};

export const LINK_TYPES = {
  Website: 'website',
  OtherLink: 'otherLink',
};

export const CREATE_PROJECT_STEPS = {
  ProjectLead: 'lead',
  ProjectDetails: 'details',
  ProjectMatchData: 'match',
  ProjectConfirmRequirements: 'confirmation',
};

export const PROJECT_TYPE = {
  FastTrack: 'fastTrack',
  Funded: 'funded',
  LearnerExperience: 'learnerExperience',
};

export const PROJECT_PAGE_SIZE = 4;

export const VIEW_MODE = { Admin: 'admin', Public: 'public' };

export const CONNECTION_STATUS = {
  WaitingForVendorResponse: 'waitingForVendorResponse',
  ConnectionAccepted: 'connectionAccepted',
  ConnectionRejectedByVendor: 'connectionRejectedByVendor',
  ConnectionRejectedByPSI: 'connectionRejectedByPSI',
  ConnectionSelectedByPSI: 'connectionSelectedByPSI',
};

export const MATCHED_VENDOR_GROUP = {
  ConnectedVendors: 1,
  VendorsOfRecord: 2,
  NonInteractedMatchedVendors: 3,
};

export const NUMBER_CONNECTION = 3;

export const DEFAULT_SERVICE_PRICE_RANGE = {
  priceLevel1Start: '0',
  priceLevel1End: '109',
  priceLevel2Start: '110',
  priceLevel2End: '165',
  priceLevel3Start: '166',
  priceLevel3End: '250',
  priceLevel4Start: '251',
  priceLevel4End: '+',
};
