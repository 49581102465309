import React from 'react';
import { Suspense, lazy } from 'react';
import MainLayout from './components/MainLayout';
import LoadingScreen from './components/LoadingScreen';
import VendorOnboarding from './pages/vendor-onboarding';
import ProjectLeadOnboarding from './pages/project-lead-onboarding';
import AuthGuard from './containers/auth/AuthGuard';
import GuestGuard from './containers/auth/GuestGuard';
import ProjectLeadOnboardingSubscriptionPlan from './pages/project-lead-onboarding/ProjectLeadOnboardingSubscriptionPlan';

const Loadable =
  (Component: React.LazyExoticComponent<() => JSX.Element>) =>
  (props: Record<string, unknown>) =>
    (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );

const DevOnly = Loadable(lazy(async () => import('./pages/dev-only')));
const Report = Loadable(lazy(async () => import('./pages/report')));
const Home = Loadable(lazy(async () => import('./pages/home')));
const SignupSuccess = Loadable(
  lazy(async () => import('./pages/signup-success'))
);
const EmailConfirmation = Loadable(
  lazy(async () => import('./pages/email-confirmation'))
);
const VendorOnboardingGeneralInfo = Loadable(
  lazy(async () => import('./pages/vendor-onboarding/GeneralInformation'))
);
const VendorOnboardingExperienceAndPlan = Loadable(
  lazy(async () => import('./pages/vendor-onboarding/ExperienceAndPlan'))
);
const VendorOnboardingIndustries = Loadable(
  lazy(async () => import('./pages/vendor-onboarding/SelectIndustries'))
);

const VendorOnboardingServices = Loadable(
  lazy(async () => import('./pages/vendor-onboarding/SelectServices'))
);
const VendorOnboardingSoftwaresAndSkills = Loadable(
  lazy(async () => import('./pages/vendor-onboarding/SelectSoftwaresAndSkills'))
);

const ProjectLeadOnboardingGeneralInfo = Loadable(
  lazy(async () => import('./pages/project-lead-onboarding/generalInformation'))
);

const Profile = Loadable(lazy(async () => import('./pages/profile')));

const Messages = Loadable(lazy(async () => import('./pages/messages')));

const UserSettings = Loadable(lazy(async () => import('./pages/user-settings/userSetting')));

const Dashboard = Loadable(lazy(async () => import('./pages/dashboard')));
const ResetPassword = Loadable(
  lazy(async () => import('./pages/reset-password'))
);

const CreateNewProject = Loadable(
  lazy(async () => import('./pages/projects/CreateNewProject/CreateNewProject'))
);

const EditDraftProject = Loadable(
  lazy(async () => import('./pages/projects/CreateNewProject/EditDraftProject'))
);

const ProjectDetail = Loadable(
  lazy(async () => import('./pages/projects/ProjectDetail'))
);
const MonthlyReport = Loadable(
  lazy(async () => import('./pages/projects/MonthlyReport'))
);
const MatchedProjectDetail = Loadable(
  lazy(
    async () => import('./pages/projects/ProjectDetail/MatchedProjectDetail')
  )
);
const MatchedVendors = Loadable(
  lazy(async () => import('./pages/matched-vendors/MatchedVendors'))
);

const LandingPage = Loadable(lazy(async () => import('./pages/landing-page')));

const TermsOfUse = Loadable(lazy(async () => import('./pages/terms-of-use')));

const PrivacyPolicy = Loadable(
  lazy(async () => import('./pages/privacy-policy'))
);

const Redirect = Loadable(lazy(async () => import('./pages/redirect')));

const routes = [
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <LandingPage />,
      },
      {
        path: 'about',
        children: [
          {
            path: '',
            element: <Home />,
          },
        ],
      },
      {
        path: 'email-confirmation',
        element: <EmailConfirmation />,
      },
      {
        path: 'signup-success',
        element: <SignupSuccess />,
      },
      {
        path: 'vendor-onboarding',
        element: <VendorOnboarding />,
        children: [
          {
            path: '',
            element: <VendorOnboardingGeneralInfo />,
          },
          {
            path: 'experience',
            element: <VendorOnboardingExperienceAndPlan />,
          },
          {
            path: 'industries',
            element: <VendorOnboardingIndustries />,
          },
          {
            path: 'services',
            element: <VendorOnboardingServices />,
          },
          {
            path: 'skills',
            element: <VendorOnboardingSoftwaresAndSkills />,
          },
        ],
      },
      {
        path: 'project-lead-onboarding',
        element: <ProjectLeadOnboarding />,
        children: [
          {
            path: '',
            element: <ProjectLeadOnboardingGeneralInfo />,
          },
          {
            path: 'subscription-plan',
            element: <ProjectLeadOnboardingSubscriptionPlan />,
          },
        ],
      },
      {
        path: 'profile',
        element: <AuthGuard />,
        children: [
          {
            path: '',
            element: <Profile />,
          },
        ],
      },
      {
        path: 'messages',
        element: <AuthGuard />,
        children: [
          {
            path: '',
            element: <Messages />,
          },
        ],
      },
      {
        path: 'user-settings',
        element: <AuthGuard />,
        children: [
          {
            path: '',
            element: <UserSettings />,
          },
        ],
      },
      {
        path: 'vendors',
        element: <AuthGuard />,
        children: [
          {
            path: ':id',
            element: <Profile />,
          },
        ],
      },
      {
        path: 'dashboard',
        element: <AuthGuard />,
        children: [
          {
            path: '',
            element: <Dashboard />,
          },
        ],
      },
      {
        path: 'projects',
        element: <AuthGuard />,
        children: [
          {
            path: 'new/:step',
            element: <CreateNewProject />,
          },
          {
            path: ':id/:step',
            element: <CreateNewProject />,
          },
          {
            path: ':editDraft/:id/:step',
            element: <EditDraftProject />,
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                element: <ProjectDetail />,
              },
              {
                path: 'monthly-report',
                element: <MonthlyReport />,
              },
              {
                path: 'matched-vendors',
                children: [
                  {
                    path: '',
                    element: <MatchedVendors />,
                  },
                  {
                    path: ':vendorId',
                    element: <Profile />,
                  },
                ],
              },
              {
                path: 'matched-project/:connectionId',
                element: <MatchedProjectDetail />,
              },
            ],
          },
        ],
      },
      {
        path: 'reset-password',
        element: <GuestGuard />,
        children: [
          {
            path: '',
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: 'landing-page',
        element: <LandingPage />,
      },
      {
        path: '404',
        element: <Home />,
      },
      {
        path: '500',
        element: <Home />,
      },
      {
        path: 'dev-only',
        element: <DevOnly />,
      },
      {
        path: 'report',
        element: <Report />,
      },
      {
        path: 'terms-of-use',
        element: <TermsOfUse />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'redirect',
        element: <Redirect />,
      },
    ],
  },
];

export default routes;
