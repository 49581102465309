import React from 'react';
import { Link, Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
  pathname: string;
  active?: boolean;
  activeColor?: string;
};

const NavItem: React.FC<Props> = ({
  children,
  pathname,
  active = false,
  activeColor,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        padding: (theme) => theme.spacing(1),
        marginRight: (theme) => theme.spacing(3),
        marginLeft: (theme) => theme.spacing(3),
        '@media screen and (max-width: 1299px)': {
          display: 'none',
        },
      }}
    >
      <Link
        href={pathname}
        underline="none"
        variant="subtitle1"
        color="textPrimary"
        sx={{
          color: (theme) =>
            active ? activeColor || 'secondary.main' : 'text.primary',
        }}
      >
        {children}
      </Link>
      {active && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 4,
            borderRadius: '10px 10px 0px 0px',
            backgroundColor: 'secondary.main',
          }}
        />
      )}
    </Box>
  );
};

export default NavItem;
