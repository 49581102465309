import { createSlice } from '@reduxjs/toolkit';
import { getMessageListReducer } from './getMessageListReducer';
import { MessageListState } from './type';

export const initialState: MessageListState = {
  isLoading: false,
  messageList: [],
  error: '',
  isProcessing: false,
};

const slice = createSlice({
  name: 'messagelist',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    getMessageListReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
