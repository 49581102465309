import { createSlice } from '@reduxjs/toolkit';
import { MessageState } from './type';
import { messageReducer } from './messageReducer';

export const initialState: MessageState[] = [{
  isLoading: false,
  timestamp: "",
  is_read: true,
  connect_id: undefined,
  from_id: undefined,
  to_id: undefined
}];

const slice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    selectSender(state, { payload }) {
      state = payload
    }
  },
  extraReducers: (builder: any) => {
    messageReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export default slice;
