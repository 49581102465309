import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useAppDispatch, useSelector } from 'store';
import { getCommonThunk } from 'slices/general/getCommonReducer';
import { getProvincesThunk } from 'slices/province/getProvincesReducer';
import { getPopularProvincesThunk } from 'slices/province/getPopularProvincesReducer';
import AuthGuard from 'containers/auth/AuthGuard';

type Props = {
  children?: React.ReactNode;
};

const ProjectLeadOnboarding: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const commonDefination = useSelector(
    (state) => state.general.commonDefination
  );
  const { provinces } = useSelector(
    (state) => state.province
  );

  useEffect(() => {
    if (!commonDefination) {
      dispatch(getCommonThunk());
    }
  }, [commonDefination]);

  useEffect(() => {
    if (!provinces) {
      dispatch(getProvincesThunk());
    }
  }, [provinces]);

  const isLoading = !commonDefination || !provinces;

  return (
    <Box bgcolor="background.default" minHeight="calc(100vh - 302px)">
      <Container sx={{ paddingY: (theme) => theme.spacing(5) }} maxWidth="lg">
        <AuthGuard>
          {isLoading ? null : children || <Outlet />}
        </AuthGuard>
      </Container>
    </Box>
  );
};

export default ProjectLeadOnboarding;