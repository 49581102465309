import { ProjectParams, editProjectApi } from "apis/project";
import { RootState } from "store/rootReducer";
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { createIndustryApi } from 'apis/industry';
import { createEndUserApi } from 'apis/endUser';
import { ProjectState, ValidationErrorResponse } from "./type";

export const editProjectThunk = createAsyncThunk(
    'project/editProject',
    async (id: number, { getState, rejectWithValue }) => {
      try {
        const state = getState() as RootState;
        const createProjectPayload = state.project.createProjectPayload;
        if (
            createProjectPayload &&
            createProjectPayload.projectType &&
            createProjectPayload.lead &&
            createProjectPayload.details &&
            createProjectPayload.match &&
            createProjectPayload.confirmation
        ) {
            const lead = createProjectPayload.lead;
            const {
                otherEndUserName,
                endUsers,
                subjectOption,
                industry: industryOption,
                otherIndustry,
                ...details
            } = createProjectPayload.details;
            const {
                chosenServices,
                otherService,
                addedService,
                // totalHours,
                ...match
            } = createProjectPayload.match;

            const chosenServicesList = Object.values(chosenServices).filter(
                (service) => service.chosen
            );

            // handle industry
            let industry = industryOption;
            if (otherIndustry) {
                const response = await createIndustryApi({ name: otherIndustry });
                industry = response.data.id;
            }

            const otherEndUser = [];
            if (otherEndUserName) {
                const { data } = await createEndUserApi({ name: otherEndUserName });
                otherEndUser.push(data.id);
            }

            const params: ProjectParams = {
                projectType: createProjectPayload.projectType,
                ...lead,
                isDraft: createProjectPayload.isDraft,
                ...details,
                ...match,
                industry,
                end_users: [...endUsers, ...otherEndUser],
                services: chosenServicesList.map((item) => item.id),
                table: {
                    data: chosenServicesList.map((service) => {
                        const { chosen, ...rest } = service;
                        return rest;
                    }),
                    // totalHours: { ...totalHours },
                },
            };
            const data = await editProjectApi(id, params);
            return data;
        } else {
            return rejectWithValue('Missing required data.');
        }
      } catch (error) {
        return rejectWithValue(error)
      }
    }
);

export const updateDraftProjectThunk = createAsyncThunk(
    'project/updateDraftProject',
    async (id: number, { getState, rejectWithValue }) => {
      try {
        const state = getState() as RootState;
        const createProjectPayload = state.project.createProjectPayload;
        const lead = createProjectPayload.lead;
        const url = window.location.pathname;
        const parts = url.split("/");
        const lastPart = parts[parts.length - 1];
        const {
          otherEndUserName,
          endUsers,
          subjectOption,
          industry: industryOption,
          otherIndustry,
          ...details
        } = createProjectPayload.details ?? {};
        const {
          chosenServices,
          otherService,
          addedService,
          // totalHours,
          ...match
        } = createProjectPayload.match ?? {};

        const chosenServicesList = chosenServices && Object.values(chosenServices).filter(
          (service) => service.chosen
        );

        // handle industry
        let industry = industryOption;
        if (otherIndustry) {
          const response = await createIndustryApi({ name: otherIndustry });
          industry = response.data.id;
        }

        const otherEndUser = [];
        if (otherEndUserName) {
          const { data } = await createEndUserApi({ name: otherEndUserName });
          otherEndUser.push(data.id);
        }

        if ('budget' in details && !details.budget) {
          details.budget = "lessThanFifteenThousand"; // Set a default value
        }
        if ('isPartVLSProject' in details && !details.isPartVLSProject) {
            details.isPartVLSProject = false; // Set a default value
        }
        if ('industry' in details && !details.industry) {
          details.industry = []; // Set a default value
        }
        
        const params: ProjectParams = {
          projectType: createProjectPayload.projectType,
          ...lead,
          isDraft: createProjectPayload.isDraft === true || lastPart !== "confirmation" ? true : false,
          ...(details && details),
          ...(match && match),
          industry: industry ? industry : 1,
          end_users: endUsers && otherEndUser ? [...endUsers, ...otherEndUser] : [],
          services: chosenServicesList && chosenServicesList.map((item) => item.id),
          table: {
            data: chosenServicesList && chosenServicesList.map((service) => {
              const { chosen, ...rest } = service;
              return rest;
            }),
            // totalHours: { ...totalHours },
          },
        };

        const { data } = await editProjectApi(id, params);
        return data;
      } catch (error) {
        return rejectWithValue(error)
      }     
    }
);

export const editProjectReducer = (
    builder: ActionReducerMapBuilder<ProjectState>
  ): void => {
    builder.addCase(editProjectThunk.pending, (state) => {
      state.isProcessing = true;
      state.error = undefined;
    });
    builder.addCase(editProjectThunk.fulfilled, (state, { payload }) => {
      state.isProcessing = false;
      state.createProjectPayload = {
        isDraft: false
      };
    });
    builder.addCase(editProjectThunk.rejected, (state, { error, payload }) => {
      state.isProcessing = false;
      if (error && error.message) {
        // Check if the error is a validation error response
        const validationError = payload as ValidationErrorResponse;
        if (validationError && validationError && validationError.details && validationError.details.errors) {
          state.validationError = validationError;
          state.error = error.message;
        } else {
          state.error = error.message; // Set other types of errors
          state.validationError = undefined;
        }
      } else {
        state.error = "An error occurred while processing the request.";
      }
    });
    builder.addCase(updateDraftProjectThunk.rejected, (state, { error, payload }) => {
      state.isProcessing = false;
      if (error && error.message) {
        // Check if the error is a validation error response
        const validationError = payload as ValidationErrorResponse;
        if (validationError && validationError && validationError.details && validationError.details.errors) {
          state.validationError = validationError;
          state.error = error.message;
        } else {
          state.error = error.message; // Set other types of errors
          state.validationError = undefined;
        }
      } else {
        state.error = "An error occurred while processing the request.";
      }
    });
  };