import { createSlice } from '@reduxjs/toolkit';
import { ProjectState, ValidationErrorResponse } from './type';
import { checkProjectTypeStatusReducer } from './checkProjectTypeReducer';
import { createProjectReducer } from './createProjectReducer';
import { getInstitutionProjectsReducer } from './getInstitutionProjectsReducer';
import { getVendorMatchedProjectsReducer } from './getVendorMatchedProjectsReducer';
import { getProjectDetailReducer } from './getProjectDetailReducer';
import { getProjectDetailWithStatusReducer } from './getProjectDetailWithStatusReducer';
import { getMatchedVendorsReducer } from './getMatchedVendorsReducer';
import { getMatchedVendorReducer } from './getMatchedVendorReducer';
import { connectToVendorReducer } from './connectToVendorReducer';
import { selectMainVendorReducer } from './selectMainVendorReducer';
import { updateProjectMatchedStatusReducer } from './updateProjectMatchedStatusReducer';
import { submitInvoiceReducer } from './submitInvoiceReducer';
import { submitMonthlyReportReducer } from './submitMonthlyReportReducer';
import { PROJECT_PAGE_SIZE } from 'constants/index';
import { editProjectReducer } from './editProjectReducer';

export const initialState: ProjectState = {
  isLoading: false,
  isProcessing: false,
  createProjectPayload: {
    isDraft: false
  },
  projectTypeStatus: {
    isEnableFunded: false,
    isEnableFastTrack: false,
    isEnableLearnerExperience: false,
  },
  institutionProjects: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: PROJECT_PAGE_SIZE,
        pageCount: 0,
        total: 0,
      },
    },
  },
  vendorProjects: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: PROJECT_PAGE_SIZE,
        pageCount: 0,
        total: 0,
      },
    },
  },
  matchedVendors: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  matchedVendor: undefined,
  error: undefined,
  validationError: undefined,
  projectDetail: undefined,
  projectConnection: undefined,
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    updateCreateProjectPayload(state, { payload }) {
      state.createProjectPayload = {
        ...state.createProjectPayload,
        ...payload,
      };
    },
    resetCreateProjectPayload(state) {
      state.createProjectPayload = {
        isDraft: false
      };
      state.projectTypeStatus = {
        isEnableFunded: false,
        isEnableFastTrack: false,
        isEnableLearnerExperience: false,
      };
    },
    resetMatchedVendorsData(state) {
      state.matchedVendors = {
        data: [],
        meta: {
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 0,
            total: 0,
          },
        },
      };
      state.projectDetail = undefined;
    },
  },
  extraReducers: (builder) => {
    checkProjectTypeStatusReducer(builder);
    createProjectReducer(builder);
    editProjectReducer(builder);
    getInstitutionProjectsReducer(builder);
    getVendorMatchedProjectsReducer(builder);
    getProjectDetailReducer(builder);
    getProjectDetailWithStatusReducer(builder);
    getMatchedVendorsReducer(builder);
    getMatchedVendorReducer(builder);
    connectToVendorReducer(builder);
    updateProjectMatchedStatusReducer(builder);
    selectMainVendorReducer(builder);
    submitInvoiceReducer(builder);
    submitMonthlyReportReducer(builder);
  },
});

export const { actions } = slice;
export const { reducer } = slice;
