import { User } from './auth';
import api from './base';
import { ProjectDetails } from './project';
import { MessageConversationList } from 'slices/messageList/type';
import { MessageDetail } from 'slices/messageDetails/type';
export interface Message {
    is_read: boolean;
    connect_id: any;
    from_id?: User,
    to_id?: User
    id: number;
    createdAt: string;
    updatedAt: string;
    message_body: string;
    is_project_owner?: boolean;
    is_service_provider?: boolean;
}
export interface SendMessageRequest {
    message_body: string;
    connect_id: number;
    to_id: number;
}
export const getMessagesApi = async () =>
    api.get('/messages') as Promise<{ data: { attributes: Message } }>;

export const getUnreadMessageCountsApi = async (id: number) =>
    api.get(`/message/get-unread-messages/${id}`) as Promise<{ data: { attributes: Message } }>;

export const getMessagesListApi = async () =>
    api.get(`/message-conversations`) as Promise<MessageConversationList[]>;

export const getMessageDetails = async (id: number) =>
    api.get(`/message-conversations/${id}`) as Promise<MessageDetail>;

export const sendMessage = async (payload: SendMessageRequest) =>
    api.post(`/messages`, payload) as Promise<{ data: { attributes: MessageDetail } }>;