import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Divider,
  Toolbar,
  Link,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Popover,
  Button,
  Typography,
  ListItemText,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { useSelector, useAppDispatch } from 'store';
import { actions } from 'slices/auth';
import { grey4, grey5, grey2 } from 'theme/color';
import { getBannersThunk } from 'slices/general/getBannersReducer';
import { getCommonThunk } from 'slices/general/getCommonReducer';
import { getPopularProvincesThunk } from 'slices/province/getPopularProvincesReducer';
import { getPopularServicesThunk } from 'slices/service/getPopularServicesReducer';
import { getPopularIndustriesThunk } from 'slices/industry/getPopularIndustriesReducer';
import { getAllSoftwaresThunk } from 'slices/software/getAllSoftwaresReducer';
import { getPopularLanguagesThunk } from 'slices/language/getPopularLanguagesReducer';
import { getPopularEndUsersThunk } from 'slices/endUser/getPopularEndUsersReducer';

import GlobeIcon from '../icons/Globe';
import ArrowDownIcon from '../icons/ArrowDown';
import { USER_ROLES } from '../constants';
import { routesConstant } from '../constants/routeConstant';
import NavItems from './NavItems';
import { updateLanguageApi } from 'apis/auth';
import NavItem from './NavItem';
import { getUnreadMessageCountThunk } from 'slices/message/messageReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 34,
      marginRight: 20,
      position: 'relative',
      top: '2px',
      [theme.breakpoints.down(440)]: {
        marginRight: 15,
        marginLeft: -4,
        height: 30,
      },
    },
    logo2: {
      height: 34,
      marginRight: 20,
      [theme.breakpoints.down(440)]: {
        height: 30,
      },
    },
    iconButton: {
      width: 50,
      height: 50,
    },
    divider: {
      height: '30px',
      borderRightWidth: '2px',
      margin: theme.spacing(2),
    },
    navBarMessages: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    navBarUnreadMessages: {
      fontSize: 12,
      color: '#fff',
      backgroundColor: '#D50000',
      borderRadius: '50%',
      minWidth: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 6px',
    },
    unreadMessages: {
      fontSize: 12,
      color: '#fff',
      backgroundColor: '#D50000',
      borderRadius: '50%',
      minWidth: '22px',
      height: '22px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 5,
      padding: '0 5px',
      position: 'relative',
      top: '-1px',
    }
  })
);

const PATHS_SHOULD_SHOW_NAV = [
  routesConstant.home,
  routesConstant.termsOfUse,
  routesConstant.privacyPolicy,
];

const languageOptions: string[] = ['en', 'fr'];

const AuthenticatedNavbar = () => {
  const { t, i18n } = useTranslation('common');
  const dispatch = useAppDispatch();
  const user = useSelector((state) => state.auth.user);
  const logout = () => {
    dispatch(actions.logout());
  };

  const location = useLocation();
  const classes = useStyles();

  const anchorMenu = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenUserMenu = () => {
    setOpenMenu(true);
  };
  const handleCloseUserMenu = () => {
    setOpenMenu(false);
  };

  const anchorLanguage = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };
  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const reloadCommonData = () => {
    dispatch(getBannersThunk());
    dispatch(getCommonThunk());
    dispatch(getPopularProvincesThunk());
    dispatch(getPopularServicesThunk());
    dispatch(getPopularIndustriesThunk());
    dispatch(getAllSoftwaresThunk());
    dispatch(getPopularLanguagesThunk());
    dispatch(getPopularEndUsersThunk());
  };

  const handleChangeLanguage = (language: string) => {
    try {
      i18n.changeLanguage(language);
      setOpenPopover(false);
      updateLanguageApi({ language }).then(() => reloadCommonData());
    } catch (error) {
      console.log(error);
    }
  };

  const selectedOption = () => {
    switch (i18n.language) {
      case 'en':
        return 'English';
      case 'fr':
        return 'Français';
      default:
        return 'English';
    }
  };

  const menuItems = [
    { name: t('navBar.dashboard'), pathname: routesConstant.dashboard },
    { name: t('navBar.profile'), pathname: routesConstant.profile },
    { name: t('navBar.messages'), pathname: routesConstant.messages },
    { name: t('navBar.userSettings'), pathname: routesConstant.userSettings },
    { name: t('navBar.helpAndSupport'), pathname: 'help' },
  ];

  const navItems = [
    { name: t('navBar.messages'), pathname: routesConstant.messages },
    { name: t('navBar.profile'), pathname: routesConstant.profile },
    { name: t('navBar.dashboard'), pathname: routesConstant.dashboard },
  ];

  const getUnreadMessageCount = () => {
    dispatch(getUnreadMessageCountThunk(user!.id)).then((action) => {
      if (action?.meta?.requestStatus === 'fulfilled') {
        setUnreadCount((action.payload as { data: any[] }).data.length);
      }
    });
  }

  useEffect(() => {
    getUnreadMessageCount();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getUnreadMessageCount();
    }, 10000); // Refresh every 10 seconds
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <div id="subnav">
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: 'background.paper',
          color: 'text.primary',
        }}
      >
        <Box paddingX={3}>
          <Toolbar disableGutters sx={{ height: 80 }}>
            <Box display="flex">
              <Link component={RouterLink} to="/" sx={{ lineHeight: 1 }}>
                <img
                  alt="ecampus"
                  src="/static/ecampus-logo-purple.svg"
                  className={classes.logo}
                />
              </Link>
              <img
                alt="ecampus"
                src="/static/central-virtual2.svg"
                className={classes.logo2}
              />
            </Box>
            {PATHS_SHOULD_SHOW_NAV.includes(location.pathname) ? (
              <NavItems />
            ) : (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'flex-start',
                  width: '100%',
                  paddingRight: '32px',
                  gap: '8px',
                }}
              >
                {navItems.map((item) => (
                  Boolean(!(user?.role?.type === USER_ROLES['Institution(ProjectLead)'] && item.name === t('navBar.profile'))) && (<NavItem
                    key={item.pathname}
                    pathname={item.pathname}
                    active={
                      !!matchPath(
                        {
                          path: item.pathname,
                        },
                        location.pathname
                      )
                    }
                  >
                    <h4 className={classes.navBarMessages}>
                      {item.name === t('navBar.messages') ? (
                        <>
                          Messages{' '}
                          {unreadCount > 0 && (
                            <span className={classes.navBarUnreadMessages}>
                              {unreadCount}
                            </span>
                          )}
                        </>
                      ) : (
                        item.name
                      )}
                    </h4>
                  </NavItem>)
                ))}
              </Box>
            )}
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '8px',
                '@media screen and (max-width: 440px)': { marginRight: '16px' },
              }}
            >
              <Box>
                <IconButton
                  ref={anchorMenu}
                  edge="end"
                  onClick={handleOpenUserMenu}
                  className={classes.iconButton}
                  aria-haspopup="true"
                >
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      backgroundColor: grey4,
                    }}
                  >
                    <span style={{ fontSize: '17px' }}>
                      {(user?.role?.type === USER_ROLES['Institution(ProjectLead)']
                        ? user?.firstName
                        : user?.fullName
                      )
                        ?.charAt(0)
                        ?.toUpperCase()}
                    </span>
                  </Avatar>
                </IconButton>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorMenu.current}
                elevation={2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 1,
                  horizontal: 'right',
                }}
                open={openMenu}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  style: {
                    width: '224px',
                  },
                }}
              >
                {menuItems.map((item, index) => {
                  return (
                    <div key={index}>
                      <MenuItem
                        onClick={handleCloseUserMenu}
                        sx={
                          user?.role.type === USER_ROLES['Institution(ProjectLead)'] &&
                            item.name === t('navBar.profile')
                            ? { display: 'none' }
                            : { paddingX: 0 }
                        }
                      >
                        <Link
                          component={RouterLink}
                          to={item.pathname}
                          underline="none"
                          fontWeight={700}
                          sx={{ width: '100%', paddingX: 3 }}
                          color={
                            matchPath(
                              {
                                path: item.pathname,
                              },
                              location.pathname
                            )
                              ? grey4
                              : grey5
                          }
                        >
                          {item.name === t('navBar.messages') ? (
                            <>
                              Messages {' '}
                              {unreadCount > 0 && (
                                <span className={classes.unreadMessages}>
                                  {unreadCount}
                                </span>
                              )}
                            </>
                          ) : (
                            item.name
                          )}
                        </Link>
                      </MenuItem>
                      {index === 2 && (
                        <>
                          <Divider color={grey2} />
                          <MenuItem
                            sx={{
                              paddingX: 3,
                              paddingRight: '10px',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              color={grey5}
                              fontWeight="fontWeightBold"
                            >
                              {selectedOption()}
                            </Typography>
                            <Button
                              size="small"
                              onClick={handleOpenPopover}
                              ref={anchorLanguage}
                              startIcon={
                                <GlobeIcon
                                  sx={{ width: '20px', height: '20px' }}
                                />
                              }
                              disableRipple
                              disableTouchRipple
                              disableFocusRipple
                              sx={{
                                height: '24px',
                                padding: 0,
                              }}
                            >
                              <ArrowDownIcon sx={{ fontSize: '18px', margin: '0 0 0 4px' }} />
                            </Button>
                            <Popover
                              anchorEl={anchorLanguage.current}
                              anchorOrigin={{
                                horizontal: 'center',
                                vertical: 'bottom',
                              }}
                              keepMounted
                              onClose={handleClosePopover}
                              open={openPopover}
                              PaperProps={{
                                sx: { width: 70 },
                              }}
                            >
                              {languageOptions.map((language) => (
                                <MenuItem
                                  onClick={() => handleChangeLanguage(language)}
                                  key={language}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography
                                        sx={{
                                          color: 'black',
                                          textTransform: 'uppercase',
                                        }}
                                        variant="subtitle1"
                                      >
                                        {language}
                                      </Typography>
                                    }
                                  />
                                </MenuItem>
                              ))}
                            </Popover>
                          </MenuItem>
                        </>
                      )}
                    </div>
                  );
                })}

                <Divider color={grey2} sx={{ marginY: 1 }} />

                <MenuItem
                  sx={{ color: grey5, fontWeight: 700, paddingX: 3 }}
                  onClick={logout}
                >
                  {t('navBar.logout')}
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Box>
        <Divider />
      </AppBar>
    </div>
  );
};
export default AuthenticatedNavbar;
