import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectParams, createProjectApi } from 'apis/project';
import { createIndustryApi } from 'apis/industry';
import { createEndUserApi } from 'apis/endUser';
import { ProjectState, ValidationErrorResponse } from './type';
import { RootState } from '../../store/rootReducer';
import { AxiosError } from 'axios';
import { stat } from 'fs';

export const createProjectThunk = createAsyncThunk(
  'project/createProject',
  async (args, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const createProjectPayload = state.project.createProjectPayload;
      if (
        createProjectPayload &&
        createProjectPayload.projectType &&
        createProjectPayload.lead &&
        createProjectPayload.details &&
        createProjectPayload.match &&
        createProjectPayload.confirmation
      ) {
        const lead = createProjectPayload.lead;
        const {
          otherEndUserName,
          endUsers,
          subjectOption,
          industry: industryOption,
          otherIndustry,
          ...details
        } = createProjectPayload.details;
        const {
          chosenServices,
          otherService,
          addedService,
          // totalHours,
          ...match
        } = createProjectPayload.match;

        const chosenServicesList = Object.values(chosenServices).filter(
          (service) => service.chosen
        );

        // handle industry
        let industry = industryOption;
        if (otherIndustry) {
          const response = await createIndustryApi({ name: otherIndustry });
          industry = response.data.id;
        }

        const otherEndUser = [];
        if (otherEndUserName) {
          const { data } = await createEndUserApi({ name: otherEndUserName });
          otherEndUser.push(data.id);
        }

        const params: ProjectParams = {
          projectType: createProjectPayload.projectType,
          ...lead,
          isDraft: createProjectPayload.isDraft,
          ...details,
          ...match,
          industry,
          end_users: [...endUsers, ...otherEndUser],
          services: chosenServicesList.map((item) => item.id),
          table: {
            data: chosenServicesList.map((service) => {
              const { chosen, ...rest } = service;
              return rest;
            }),
            // totalHours: { ...totalHours },
          },
        };
        const { data } = await createProjectApi(params);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err)
    }
  }
);

export const draftProjectThunk = createAsyncThunk(
  'project/draftProject',
  async (args, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const createProjectPayload = state.project.createProjectPayload;
    const lead = createProjectPayload.lead;
    const {
      otherEndUserName,
      endUsers,
      subjectOption,
      industry: industryOption,
      otherIndustry,
      ...details
    } = createProjectPayload.details ?? {};
    const {
      chosenServices,
      otherService,
      addedService,
      // totalHours,
      ...match
    } = createProjectPayload.match ?? {};

    const chosenServicesList = chosenServices && Object.values(chosenServices).filter(
      (service) => service.chosen
    );

    // handle industry
    let industry = industryOption;
    if (otherIndustry) {
      const response = await createIndustryApi({ name: otherIndustry });
      industry = response.data.id;
    }

    const otherEndUser = [];
    if (otherEndUserName) {
      const { data } = await createEndUserApi({ name: otherEndUserName });
      otherEndUser.push(data.id);
    }

    const params: ProjectParams = {
      projectType: createProjectPayload.projectType,
      ...lead,
      isDraft: true,
      ...(details && details),
      ...(match && match),
      industry,
      end_users: endUsers && otherEndUser ? [...endUsers, ...otherEndUser] : [],
      services: chosenServicesList && chosenServicesList.map((item) => item.id),
      table: {
        data: chosenServicesList && chosenServicesList.map((service) => {
          const { chosen, ...rest } = service;
          return rest;
        }),
        // totalHours: { ...totalHours },
      },
    };

    const { data } = await createProjectApi(params);
    return data;
  }
);

export const createProjectReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
): void => {
  builder.addCase(createProjectThunk.pending, (state) => {
    state.isProcessing = true;
    state.validationError = undefined;
    state.error = undefined;
  });
  builder.addCase(createProjectThunk.fulfilled, (state, { payload }) => {
    state.isProcessing = false;
    state.createProjectPayload = {
      isDraft: false
    };
  });
  builder.addCase(createProjectThunk.rejected, (state, { error, payload }) => {
    state.isProcessing = false;
    if (error && error.message) {
      // Check if the error is a validation error response
      const validationError = payload as ValidationErrorResponse;
      if (validationError && validationError && validationError.details && validationError.details.errors) {
        state.validationError = validationError;
        state.error = error.message;
      } else {
        state.error = error.message; // Set other types of errors
        state.validationError = undefined;
      }
    } else {
      state.error = "An error occurred while processing the request.";
    }
  });
};

