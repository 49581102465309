import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { MessageState } from './type';
import { getMessagesApi, getUnreadMessageCountsApi } from 'apis/message';

export const getMessageThunk = createAsyncThunk(
  'messages',
  async () => await getMessagesApi()
);

export const getUnreadMessageCountThunk = createAsyncThunk(
  'unread-messages',
  async (id: number) => await getUnreadMessageCountsApi(id)
);

export const messageReducer = (
  builder: ActionReducerMapBuilder<MessageState>
): void => {
  builder.addCase(getMessageThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getMessageThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.messages = payload.data.attributes;
  });
  builder.addCase(getMessageThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
