import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagesListApi } from 'apis/message';
import { MessageListState } from './type';

export const getMessageListThunk = createAsyncThunk(
    '/message/messageList',
    async () => {
        const messageList = await getMessagesListApi();
        return messageList;
    }
);

export const getMessageListReducer = (
    builder: ActionReducerMapBuilder<MessageListState>
): void => {
    builder.addCase(getMessageListThunk.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
    });
    builder.addCase(getMessageListThunk.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.messageList = payload;
    });
    builder.addCase(getMessageListThunk.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error.message;
    });
};
