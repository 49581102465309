import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getGlobalSettingsApi } from 'apis/globalSetting';
import { GlobalSettingState } from './type';

export const getGlobalSettingThunk = createAsyncThunk(
  'global-setting',
  async () => await getGlobalSettingsApi()
);

export const getGlobalSettingReducer = (
  builder: ActionReducerMapBuilder<GlobalSettingState>
): void => {
  builder.addCase(getGlobalSettingThunk.pending, (state) => {
    state.isLoading = true;
    state.error = undefined;
  });
  builder.addCase(getGlobalSettingThunk.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.globalSetting = payload.data.attributes;
  });
  builder.addCase(getGlobalSettingThunk.rejected, (state, { error }) => {
    state.isLoading = false;
    state.error = error.message;
  });
};
